import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css'

function App() {
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    // Gọi API để lấy dữ liệu từ file txt
    axios.get('https://skin-be.vercel.app/api/accounts')
      .then(response => {
        setAccounts(response.data);
      })
      .catch(error => {
        console.error("Có lỗi xảy ra: ", error);
      });
  }, []);

  return (
    <div className="App">
      {accounts.map((account, index) => (
        <div key={index} className="account">
          <h2>{account.gamename}</h2>
          <h3>Skins: {account.skins.length}</h3>
          <div className='card-products'>
            {account.skins.map((skin, idx) => (
              <div className='products' key={idx}>
                {skin !== null &&
                  <img
                    src={`/images/${skin.replace(/[^a-zA-Z0-9]/g, '_')}.png`}
                    alt={skin}
                    width="100"
                  />
                }
                <p>{skin}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default App;
